import React from 'react'
import { Link, useParams } from 'react-router-dom'; // useParams is used to capture route parameters

function SingleRecipePage({ sortedRecipes }) {
    const { id } = useParams()

    // const proj_list = sortedRecipes?.map((recipe) => recipe) // mapping through each data
    const recipe = sortedRecipes?.find((recipe) => recipe.id === parseInt(id)) // chose a specific data by id

    return (
        <>
            <div>{recipe.name}</div>
            <div>{recipe.created_at}</div>
            <div>{recipe.duration}</div>
            <div>{recipe.difficulty}</div>
            <div>{recipe.description}</div>
        </>
    )
}

export default SingleRecipePage