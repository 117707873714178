import React from 'react'

function UserSubhead() {

  // Subhead will consist of fav recipes and pinned recipe
  return (
    <div className="flex justify-center items-center">UserSubhead</div>
  )
}

export default UserSubhead