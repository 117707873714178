import React from 'react'
import { useParams, Link } from "react-router-dom"
import Header from "../header/header"
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');  // months are 0-indexed in JS
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function MobileFoodDetailsContainer({ sortedRecipes }) {
    const { id } = useParams()
    const [currentUser, loading, error] = useAuthState(auth)

    const recipe_list = sortedRecipes?.map((recipe) => recipe) // mapping each recipe from sortedRecipes that passed from parent
    const recipe = recipe_list.find((recipe) => recipe.id === parseInt(id)) // choose a specific recipe that have same id 
 
    if (!recipe) {
        return <p>Loading...</p>
      }

    return (
        <div className="layout h-screen w-full">
            <Header currentUser={ currentUser } />
            { currentUser ?
                <div className="flex flex-col mt-[2rem] mx-[1rem] space-y-[1rem]">
                    <div>Posted on {formatDate(recipe.created_at)}</div>
                    <h1 className="text-xl font-bold">{recipe.name}</h1>
                    <div className="flex my-[0.5rem] items-center justify-center">
                        <img className="object-cover h-[300px] w-[300px]" src={recipe.image} alt="kimchi-jigae" />
                    </div>
                    <div className="my-[0.5rem]">
                        {recipe.description}
                    </div>
                    <div className="flex flex-col mt-[2rem]">
                        <h2 className="text-ml font-bold">Instruction:</h2>
                        {recipe.instructions}
                    </div>
                    <div>
                        <hr class="w-48 h-1 mx-auto my-[2rem] bg-black border-0 rounded md:my-10 dark:bg-black" />
                    </div>
                    <footer className="footer">
                        <div className='footer-text text-center'>
                            &copy; 2023 MukJa. All Rights Reserved. 
                        </div>
                    </footer>  
                </div>
                : 
                <div>
                    <div className="flex justify-center items-center mt-[5rem]">Please login to view recipes!</div>
                </div>
            }
        </div>
    )
}

export default MobileFoodDetailsContainer