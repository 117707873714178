import React, { useState, useContext, useEffect } from 'react'
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from '../../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    // to signin with using email and password
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    // to signout with using email and password
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    // to logout
    function logout() {
        return signOut(auth)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        signup,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
} 
