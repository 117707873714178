import React, { useState, useEffect } from 'react'
import { useAuth } from '../auth/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'

import RecipeCard from './recipeCard'
import UserPageHeader from '../header/userPageHeader'
import UserSubhead from './userSubhead'

function UserPage({ recipe, uids }) {
    const { currentUser } = useAuth();
    const { userID } = useParams();
    const navigate = useNavigate();


    // console.log("recipe in user page:")
    // console.log(recipe)

    const isAdmin = currentUser && (currentUser.uid === process.env.REACT_APP_ADMINID || currentUser.role === "admin");


    useEffect(() => {
        // console.log("currentUser UID:", currentUser?.uid);
        // console.log("URL userID:", userID);
        
        if (!currentUser) {
            navigate('/login');
            return;
        }
    
        // Check if the user is NOT on their own account page
        if (currentUser.uid !== userID) {
            navigate(`/myaccount/${currentUser.uid}`);
        }
        
    }, [currentUser, userID, navigate]);


    // fitered out the data that matches with current uid with current user's uid
    const userRecipes = isAdmin ? recipe.map(item => item.data) || [] : recipe?.filter(item => item.auth?.uid === currentUser?.uid).map(item => item.data) || [];
    // console.log('User Recipes:', userRecipes);


    return (
        <>
            <UserPageHeader currentUser={currentUser} />
            <UserSubhead />
            { 
                currentUser ? 
                userRecipes.length ? // Checking if there's any available display data
                <div>
                    <div className="ml-[11rem] mt-[5rem]">
                        <p className="text-lg">Your Recipes:</p>
                    </div>
                    <div className="flex flex-row mt-[1rem] md:mx-[5rem]">
                        <div className="flex flex-wrap mt-[1rem] md:mx-[5rem]">
                            {userRecipes.map((recipe) => (
                                <div key={recipe.id} className="w-full lg:w-1/2 p-4">
                                    <RecipeCard recipe={recipe} currentUser={currentUser} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> 
                : 
                <div className="ml-[11rem] mt-[5rem]">No Recipes Added Yet. Go make your own Recipe!</div>
                :
                <div>Please Login</div>
            }
        </>
    )
}

export default UserPage