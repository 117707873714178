import react, { useState } from "react"

export default function SearchComponent({ setSearchTerm }) {
  const [isFocused, setIsFocused] = useState(false)

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <form className="right-2 lg:bottom-8 md:bottom-8">
        <div className="relative">
            <input
                className="w-full text-[1.2rem] py-1 pl-12 bg-transparent text-gray-500 border-2 rounded-lg outline-none"
                type="text"
                placeholder="Search"
                onChange={handleInputChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            <svg 
                className={`absolute top-1/2 transform -translate-y-1/2 left-2 ${isFocused ? "scale-50" : "scale-100"} transition duration-300 text-gray-500`} 
                width="24" 
                height="24"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2"
            >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
        </div>
    </form>
  )
}