import React, { useState, useEffect, useLayoutEffect } from 'react'
import Header from '../header/header'
import RecipeBook from '../ui/recipeBook'
import FoodListContainer from './foodListContainer'
import FoodDetailsContainer from './foodDetailsContainer'
import MobileFoodListContainer from './mobileFoodListContainer'
import MobileFoodDetailsContainer from './mobileFoodDetailsContainer'
import RecipeAdder from './recipeAdder'
import DefaultDetailContainer from './defaultDetailContainer'
import SearchComponent from '../ui/searchBar'
import { useAuth } from '../auth/AuthContext'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import axios from 'axios'
import { useParams } from 'react-router-dom' // useParams is used to capture route parameters

function Home({recipe, sortedRecipes, setPage, page, pageSize, total, showRecipe, setShowRecipe}) {
  // This displays recipe book when "RecipeBook" component is clicked
  // const { currentUser } = useAuth()
  const [isMobile, setIsMobile] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [currentUser, loading, error] = useAuthState(auth)

  const [searchTerm, setSearchTerm] = useState("")
  const [resultedRecips, setResultedRecipes] = useState([])



  // Capturing the id from the route parameter
  const { id } = useParams()

  const selectedRecipes = sortedRecipes.find(recipe => recipe.id === parseInt(id))

  // console.log("Recipe Details:")
  // console.log(recipe) // auth and data
  // console.log("Sorted Recipe:")
  // console.log(sortedRecipes) // data that sorted with created_at
  // console.log("Selected Recipe:")
  // console.log(selectedRecipes)

  useEffect(() => {
    // updateMedia()
    const filteredRecipes = sortedRecipes?.filter(recipes => {
      let matchResult = (
        recipes.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        recipes.description.toLowerCase().includes(searchTerm.toLowerCase())
      )

      return matchResult
    })

    const sortedAndFilteredRecipes = filteredRecipes?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    const resultedRecipes = sortedAndFilteredRecipes?.slice(0, page * pageSize)
    setResultedRecipes(resultedRecipes)

  }, [page, pageSize, sortedRecipes, searchTerm])

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 640)
    setIsDesktop(window.innerWidth >= 640)
  }

  useLayoutEffect(() => {
    updateMedia();
  }, [])

  if (!recipe) {
    return <p>Loading...</p>
  }

  // console.log("current user display : home.js")
  // // currentUser.uid shows the unique user id
  // console.log(currentUser.uid)

  return (
    <div className="bg-blue">
      <Header currentUser={ currentUser } />
      <div className='w-[85%] md:w-[90%] ml-[2rem] lg:ml-[5rem] mt-4'> 
          <SearchComponent setSearchTerm={setSearchTerm} />  
      </div>
      <div className="mt-[4rem] sm:ml-[5rem] ml-[1rem]">
        {/* when recipebook is clicked then absolute div will show up to help users to fill out food that they're looking */}
        <RecipeBook setShowRecipe={setShowRecipe} />
        {showRecipe && <RecipeAdder setShowRecipe={setShowRecipe} currentUser={currentUser} />}
      </div>
      {
        currentUser ? isDesktop && 
        <div className="grid md:grid-cols-2 grid-cols-1 mt-[5rem] md:mx-[5rem]">
          <div>
            {resultedRecips?.map((recipe) => <FoodListContainer key={recipe.id} recipe={recipe} />)}

            {/* Pagination */}
            <div className='flex justify-center text-center'>
                {
                    page * pageSize < total 
                    ? 
                    <button 
                        className='mt-[2rem] mb-[2rem] bg-white text-lg hover:border-black text-black py-2 px-20 border font-semibold border-gray-400 rounded shadow'
                        type='button'
                        onClick={(e) => {
                            e.preventDefault()
                            // e.stopPropagation();
                            setPage(page + 1)
                        }}
                    >
                        Load more 
                    </button>
                    : 
                    <div className="mt-[2rem] mb-[2rem]">End of Recipes</div>
                }
            </div>

          </div>
          <div className="sticky top-0">
            { 
            loading ? <div>Loading ... </div> : selectedRecipes ? <FoodDetailsContainer selectedRecipes={selectedRecipes} /> : < DefaultDetailContainer selectedRecipes={sortedRecipes[0]} />
            }
          </div>
        </div> 
        : 
        <div>
          <div className="flex justify-center items-center mt-[5rem]">Please login to view recipes!</div>
        </div>
      }

      {/* Mobile View */}
      {
        currentUser ? isMobile && 
        <div className="grid grid-cols-1 mt-[5rem] md:mx-[5rem]">
          <div>
            {resultedRecips?.map((recipe) => <MobileFoodListContainer key={recipe.id} recipe={recipe} />)}

            {/* Pagination */}
            <div className='flex justify-center text-center'>
                {
                    page * pageSize < total 
                    ? 
                    <button 
                        className='mt-[2rem] mb-[2rem] bg-white text-lg hover:border-black text-black py-2 px-20 border font-semibold border-gray-400 rounded shadow'
                        type='button'
                        onClick={(e) => {
                            e.preventDefault()
                            // e.stopPropagation();
                            setPage(page + 1)
                        }}
                    >
                        Load more 
                    </button>
                    : 
                    <div className="mt-[2rem] mb-[2rem]">End of Recipes</div>
                }
            </div>

          </div>
          {/* <div className="sticky top-0">
            { 
            loading ? <div>Loading ... </div> : selectedRecipes ? <FoodDetailsContainer selectedRecipes={selectedRecipes} /> : < DefaultDetailContainer selectedRecipes={sortedRecipes[0]} />
            }
          </div> */}
        </div> 
        : 
        <div>
          <div className="flex justify-center items-center mt-[5rem]">Please login to view recipes!</div>
        </div>
      }
      <footer className='text-center'>
        &copy; 2023 MukJa. All Rights Reserved. 
      </footer>  
    </div>
  )
}

export default Home