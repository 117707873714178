import React from 'react'
import { useParams, Link } from "react-router-dom"

function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return  rhours + " hr " + rminutes + " min";
}

function MobileFoodListContainer({ recipe }) {
    const { id } = useParams()

    // console.log(id)

    if (!recipe) {
        <div></div>
    }

    const recipeLink = `/${recipe.id}`

    return (
        <>
            <Link to={recipeLink} key={recipe.id}>
                <div className="flex flex-row justify-between border-2 hover:border-black p-[1rem]">
                    <div className="w-1/2">
                        <img className="object-cover h-[300px] w-[300px]" src={recipe.image} alt="kimchi-jigae" />
                    </div>
                    <div className="w-1/2 text-lg">
                        <div className="flex flex-col md:ml-[0rem] ml-[0.8rem]"> 
                            <div className="font-bold">
                                {recipe.name}
                            </div>
                            <div>
                                Duration: {timeConvert(recipe.duration)}
                            </div>
                            <div>
                                Difficulty: {recipe.difficulty}/10
                            </div>
                            <div className="mt-[1rem]">
                                About: 
                            </div>
                            <div>
                                {recipe.description}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default MobileFoodListContainer