import React, { useState, useEffect } from 'react'
import axios from 'axios'

function RecipeAdder({ setShowRecipe, currentUser }) {
    const [measures, setMeasures] = useState([])

    // for selecting measures from backend
    const [difficulty, setDifficulty] = useState("");
    const [recipeDuration, setRecipeDuration] = useState("");
    const [image, setImage] = useState("");
    const [recipeName, setRecipeName] = useState("");
    const [recipeDescription, setRecipeDescription] = useState("");
    const [instructions, setInstructions] = useState("");
    const [instructionsError, setInstructionsError] = useState("");    
    const [ingredients, setIngredients] = useState([{
        amount: '',
        ingredient: { name: '' },
        measure: { id: 1 , name: '' }
    }])
    
    // this useState is for adding message to indicate the user that the message is added
    const [statusMessage, setStatusMessage] = useState({ message: '', type: '' });

    const measureData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVERURL}/measures`);
            setMeasures(response.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error.message);
        }
    }

    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVERURL}/upload/image`, formData, {
                headers: {
                    "Authorization": `Bearer ${currentUser.accessToken}`,
                    "Content-Type": "multipart/form-data"  // Explicitly setting content type,
                }
            });
            
            if (response.status === 200) {
                return response.data.url;  // assuming your backend returns the image URL in a property called "url"
            } else {
                console.error("Unexpected response status while uploading image:", response.status);
                throw new Error('Image upload failed with status ' + response.status);
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error("Error Response Data:", error.response.data);
                console.error("Error Response Status:", error.response.status);
                console.error("Error Response Headers:", error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received. Error Request:", error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Error Message:", error.message);
            }
            console.error("Error Config:", error.config);
            throw error;  // Propagate the error up so it can be handled in handleSubmit
        }
    }


    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
    }

    // count instruction word counts
    function validateWordCount(text) {
        const words = text.split(/\s+/).filter(Boolean); // splits by whitespace and filters out any empty strings
        return words.length >= 20;
    }

    const updateIngredient = (index, field, value) => {
        const updatedIngredients = [...ingredients];
        if (field === 'name') {
            updatedIngredients[index].ingredient.name = value;
        } else if (field === 'amount') {
            updatedIngredients[index].amount = value;
        } else if (field === 'unit') {
            // Convert the "none" string back to null when updating the state
            updatedIngredients[index].measure.id = value;
        }
        setIngredients(updatedIngredients);
    };

    const addNewIngredient = () => {
        const lastIngredient = ingredients[ingredients.length - 1];
        if (lastIngredient.ingredient.name && lastIngredient.amount) {
            setIngredients([...ingredients, {
                amount: '',
                ingredient: { name: '' },
                measure: { id: 1 }
            }]);
        } else {
            alert('Please fill out the last ingredient details before adding a new line.');
        }
    };
    

    // deleting ingredient row
    const removeIngredient = (index) => {
        const newIngredients = [...ingredients];
        newIngredients.splice(index, 1);
        setIngredients(newIngredients);
    }

    useEffect(() => {
        measureData()
    }, [])
    
    // handling recipe submits
    const handleSubmit = async (e) => {
        e.preventDefault();  // prevent the default form submission

        let uploadedImagePath;
        if (image) {
            try {
                uploadedImagePath = await handleImageUpload(image);
                setImage(uploadedImagePath); // this updates the state for the next render
            } catch (error) {
                alert('There was an issue uploading the image. Please try again.');
                return;
            }
        } else {
            uploadedImagePath = null;
        }

        if (recipeName === "") {
            // Notify the user to select a valid measure.
            alert('Please fill out recipe name.');
            return;
        }

        if (difficulty === "Select Difficulty") {
            // Notify the user to select a valid measure.
            alert('Please choose the difficulty level.');
            return;
        }

        if (!validateWordCount(instructions)) {
            setInstructionsError("Instructions must have at least 20 words.");
            return;
        }

        
        const formData = {
            name: recipeName,
            description: recipeDescription,
            image: uploadedImagePath,
            instructions: instructions,
            difficulty: parseInt(difficulty, 10),
            duration: parseInt(recipeDuration, 10),
            firebase_uid: currentUser.uid,
            recipe_ingredients: ingredients.map(ingredient => ({
                amount: parseInt(ingredient.amount, 10),
                ingredient: { name: ingredient.ingredient.name },
                measure: { id: ingredient.measure.id }
            }))
        }

        console.log("Uploaded Image Path:", uploadedImagePath);
        console.log('Sending data:', formData);

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVERURL}/upload/recipes`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${currentUser.accessToken}`
                }
            });
        
            if (response.status === 201) {
                // alert("Recipe added successfully!");
                setStatusMessage({ message: 'Recipe Added successfully!', type: 'success' });
                setTimeout(() => {
                    window.location.reload(); // refresh the page after editing
                }, 2500); // 2 sec
                // Optionally: Clear form or navigate to another page
            } else {
                alert("Error adding recipe: " + response.data.error);
            }
        } catch (error) {
            console.error("There was an error uploading the data", error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('General error:', error.message);
            }
        } 
    }

    return (
        <>
            {statusMessage.message && (
                <div className={`fixed inset-0 flex items-center justify-center z-50`}>
                    <div className={`m-6 p-4 rounded ${statusMessage.type === 'success' ? 'bg-green-300' : 'bg-red-300'}`}>
                        {statusMessage.message}
                    </div>
                </div>
            )}
            <div className="absolute h-screen md:w-[45%] w-full bg-white border-[1px] rounded-md border-black mt-[5rem] overflow-y-auto">
                
                {/* Top section to close the adder container and title */}
                <div className="flex justify-between p-[2rem]">
                    <div className="ml-[1rem] text-2xl">
                        Add Your Recipe
                    </div>
                    <button 
                        className="w-[1.7rem] rounded-full border-2 hover:bg-black hover:text-white"
                        onClick={() => setShowRecipe(false)}>
                        x
                    </button>
                </div>

                {/* A form section */}
                <form className="flex flex-col ml-[2rem]" onSubmit={handleSubmit}>
                    <div className="mt-[2rem] flex flex-col">
                        <label className="ml-[1rem] text-lg">Recipe</label>
                        <div>
                            <input 
                                className="ml-[1rem] border-2 rounded-lg" 
                                name='title' 
                                maxLength={30} 
                                placeholder='Your Recipe Name' 
                                value={recipeName}
                                onChange={e => setRecipeName(e.target.value)}
                            />
                        </div>
                    </div>


                    <div className="mt-[2rem] flex flex-col">
                        <div className="flex flex-row">
                            <div className="flex flex-col">
                                <label className="ml-[1rem] text-lg">Expected Duration</label>
                                <div>
                                    <input 
                                        className="ml-[1rem] border-2 rounded-lg" 
                                        name='duration' 
                                        maxLength={5} 
                                        placeholder='Duration (minutes)' 
                                        value={recipeDuration}
                                        onChange={e => setRecipeDuration(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <label className="ml-[1rem] text-lg">Difficulty Level</label>
                                <div>
                                    <select 
                                        className="ml-[1rem] border-2 rounded-lg" 
                                        value={difficulty} 
                                        onChange={e => setDifficulty(e.target.value)}
                                    >
                                        <option value="" disabled selected>Select Difficulty</option>
                                        {[...Array(10).keys()].map(num => 
                                            <option key={num + 1} value={num + 1}>{num + 1}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="mt-[2rem] flex flex-col">
                        <label className="ml-[1rem] text-lg">Recipe Description</label>
                        <div>
                            <textarea 
                                className="ml-[1rem] border-2 rounded-lg w-11/12 h-[5rem] text-ellipsis overflow-hidden" 
                                type="text" 
                                placeholder='Describe About Your Recipe' 
                                value={recipeDescription}
                                onChange={e => setRecipeDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    
                    <div className="mt-[2rem] flex flex-col">
                        <label className="ml-[1rem] text-lg">Ingredient(s)</label>
                        {ingredients.map((ingredientItem, index) => (
                            <div key={index} className="flex flex-row">
                                <input
                                    className="ml-[1rem] border-2 rounded-lg"
                                    maxLength={30}
                                    placeholder='Ingredient'
                                    value={ingredientItem.ingredient.name}
                                    onChange={e => updateIngredient(index, 'name', e.target.value)}
                                />
                                <input
                                    className="ml-[1rem] border-2 rounded-lg"
                                    maxLength={30}
                                    placeholder='Amount'
                                    value={ingredientItem.amount}
                                    onChange={e => updateIngredient(index, 'amount', e.target.value)}
                                />
                                <select 
                                    className="ml-[1rem] border-2 rounded-lg" 
                                    value={ingredientItem.measure.id}
                                    onChange={e => updateIngredient(index, 'unit', e.target.value)}
                                >
                                    {measures.map((measure) => (
                                        <option key={measure.id} value={measure.id}>{measure.name}</option>
                                    ))}
                                </select>
                                {index !== 0 && <button onClick={() => removeIngredient(index)} className="border-2 rounded-md ml-[1rem] w-[4rem]">Delete</button>}
                            </div>
                        ))}
                        <button type="button" onClick={addNewIngredient} className="border-2 rounded-md ml-[1rem] w-[4rem]">Add</button>
                    </div>


                    <div className="mt-[2rem] flex flex-col mb-[2rem]">
                        <label className="ml-[1rem] text-lg">Instruction(s)</label>
                        <textarea 
                            className="ml-[1rem] border-2 rounded-lg w-11/12 h-[30rem] text-ellipsis overflow-hidden" 
                            type="text" 
                            placeholder='Ingredient' 
                            value={instructions}
                            onChange={e => setInstructions(e.target.value)}
                        />
                        {/* The code right below is telling you how much word is written down in the container */}
                        <span className="ml-[1rem] text-sm text-gray-600">{instructions.split(/\s+/).filter(Boolean).length} words / 100 words (Minimum)</span>
                        {instructionsError && <span className="text-red-500">{instructionsError}</span>}
                        <div className="mt-[2rem] flex flex-col">
                            <div>
                                <input 
                                type="file" 
                                accept="image/*" 
                                onChange={handleFileChange}
                                />
                            </div>
                        </div>
                    </div>


                    <button type="submit" className="border-2 rounded-md ml-[1rem] w-[4rem] mb-[2rem]">Submit</button>

                </form>
            </div>
        </>
    )
}

export default RecipeAdder