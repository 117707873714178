// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, GoogleAuthProvider, GithubAuthProvider, signInWithPopup, signInWithRedirect, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_SENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth()

// Google provided signin function
const provider = new GoogleAuthProvider()
const gitProvider = new GithubAuthProvider()

const analytics = getAnalytics(app)

export {app, auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, provider, gitProvider, signInWithPopup, signInWithRedirect, onAuthStateChanged}