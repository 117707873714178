import React, { useState } from 'react'
import DeleteConfirm from './deleteConfirm';
import EditRecipe from './editRecipe';
import { Link, useParams } from 'react-router-dom'; // useParams is used to capture route parameters

function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return  rhours + " hr " + rminutes + " min";
}

function RecipeCard({ recipe, onDelete, currentUser }) {
    const [showDeleted, setShowDeleted] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const handleDelete = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVERURL}/${recipe.id}`, {
                method: 'DELETE',
            });

            const data = await response.json();

            if (response.ok) {
                // alert(data.message || "Recipe deleted successfully!");
                if (onDelete) onDelete(recipe.id);  // Notify the parent component
                setTimeout(() => {
                    window.location.reload(); // refreshing the page after a delay
                }, 2000)
            } else {
                alert(data.error || "Error deleting the recipe.");
            }
        } catch (error) {
            console.error("Error deleting recipe:", error);
            alert("Failed to delete recipe.");
        }
    }

    // console.log(recipe) // if its object, you don't need to iterate like an array type data.
    const recipeLink = `/recipe/${recipe.id}`

    return (
        <>
            <div className="flex flex-col">
            <button
                onClick={() => setShowEdit(true)}
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            >
                Edit
            </button>

            {showEdit && (
                <EditRecipe
                    recipeId={recipe.id}
                    currentUser={currentUser}
                    onClose={() => setShowEdit(false)}
                />
            )}
                <div className="flex justify-between border-2 hover:border-black p-4">
                    <div className="w-1/2">
                        <Link to={recipeLink} key={recipe.id} >
                            <img className="object-cover h-[300px] w-[300px]" src={recipe.image} alt="kimchi-jigae" />
                        </Link>
                    </div>

                    <div className="w-1/2 text-lg ml-[0.5rem]">
                        <div className="flex flex-col md:ml-0 ml-2"> 
                            <Link to={recipeLink} key={recipe.id} >
                                <div className="font-bold">
                                    {recipe.name}
                                </div>
                            </Link>
                            <div>
                                Duration: {timeConvert(recipe.duration)}
                            </div>
                            <div>
                                Difficulty: {recipe.difficulty}/10
                            </div>
                            <div className="mt-4">
                                About: 
                            </div>
                            <div>
                                {recipe.description}
                            </div>
                            <button
                                    // onClick={handleDelete}
                                    onClick={() => setShowDeleted(true)}
                                    className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                            >
                                Delete
                            </button>
                            {showDeleted && (
                                <DeleteConfirm
                                onConfirm={() => {
                                    handleDelete() // when yes is clicked, notification result is set to true
                                    console.log('Deleted sent')
                                    // the modal won't close when "Yes" is clicked anymore since we've removed setShowConfirmation(false)
                                }}
                                onCancel={() => setShowDeleted(false)} // this will close the modal when "No" is clicked or when the overlay is clicked
                                />
                            )}
                        </div>
                    </div>
            </div>
            </div>
        </>

    )
}

export default RecipeCard