import React from 'react'

function DefaultFoodDetailsContainer({ selectedRecipes }) {

    if (!selectedRecipes) {
        return <div></div>
    }

  return (
    <div className="sticky top-0 md:flex flex-col border-2 p-[1rem] hidden">
        <div className="text-lg font-bold">
            About {selectedRecipes.name}:
        </div>
        <div>
            {selectedRecipes.description}
        </div>
        <div className="mt-[2rem] text-lg font-bold">
            Ingredient(s):
        </div>
        <div className="flex flex-col">
            <ul>
                {selectedRecipes && selectedRecipes.recipe_ingredients && selectedRecipes.recipe_ingredients.map((recipeIngredient, index) => (
                    <li key={index}>
                    {recipeIngredient.ingredient && recipeIngredient.ingredient.name} 
                    {/* Assuming the ingredient object has a name property */}
                    
                    {
                       ` - ${recipeIngredient.amount}`
                    }

                    {
                        recipeIngredient.measure && recipeIngredient.measure.name && ` ${recipeIngredient.measure.name}`
                    }
                    {/* This will display the measure's name if it exists */}
                    </li>
                ))}
            </ul>
        </div>
        <div className="mt-[2rem] text-lg font-bold">
            Instruction(s):
        </div>
        <div className="flex flex-col">
        {
            selectedRecipes.instructions.split('\n').map((paragraph, index) => (
                <p className="texts pb-[0.5rem]" key={index}>{paragraph}</p>
            ))
        }
        </div>
    </div>
  )
}

export default DefaultFoodDetailsContainer