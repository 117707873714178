import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from "./components/homepage/home"
import Signup from './components/auth/signUp'
import Login from './components/auth/login'
import { AuthProvider } from './components/auth/AuthContext.js'
import FoodDetailsContainer from './components/homepage/foodDetailsContainer';
import UserPage from './components/userpage/userPage'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import GoogleAuth from './components/auth/googleAuth'
import SingleRecipePage from './components/userpage/singleRecipePage'
import MobileFoodDetailsContainer from './components/homepage/mobileFoodDetailsContainer'
import DefaultFoodDetailsContainer from './components/homepage/defaultDetailContainer'

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 640)
  const [showRecipe, setShowRecipe] = useState(false)
  const [recipe, setRecipe] = useState([]) // setting type from object to array
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const pageSize = 3

  useEffect(() => {
    const fetchAllRecipes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVERURL}/recipes`)
        // const response = await axios.get('http://ec2-18-226-159-62.us-east-2.compute.amazonaws.com:8080/recipes')
        setRecipe(response.data) // this data includes both auth and data
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the recipes!", error)
      }
    };
    fetchAllRecipes()

    // code below is allowing website screen to resize itself
    window.addEventListener('resize', updateMedia)
    return () => {
      window.removeEventListener('resize', updateMedia)
    };
  }, [])

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 640)
    setIsDesktop(window.innerWidth >= 640)
  }

  // mapping UIDs
  const uids = recipe.map(item => item.auth?.uid).filter(Boolean);
  
  // sorting recipes
  const sortedRecipes = recipe.map(recipeItem => recipeItem.data).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const pagedRecipes = sortedRecipes?.slice(0, page * pageSize)

  return (
    <>
      <Router>
        <div className="App">
          <AuthProvider>
            <Routes>
              <Route exact path="/" element={ <Home recipe={recipe} sortedRecipes={pagedRecipes} setPage={setPage} page={page} pageSize={pageSize} total={sortedRecipes.length} showRecipe={showRecipe} setShowRecipe={setShowRecipe} loading={loading} /> }>
                {isDesktop && <Route path=":id" element={ <FoodDetailsContainer /> } />}
                {isDesktop && <Route path="home" element={< DefaultFoodDetailsContainer />} />}
              </Route>

              {/* Single Page Recipe */}
              <Route path="/recipe/:id" element={ <SingleRecipePage sortedRecipes={sortedRecipes} /> } />

              {/* Mobile Page */}
              {isMobile && <Route path="/:id" element={<MobileFoodDetailsContainer sortedRecipes={pagedRecipes} />} />}

              {/* <Route path="/signup" element={ <Signup /> } /> */}
              {/* <Route path="/login" element={ <Login /> } /> */}
              <Route path="/login" element={ <GoogleAuth /> } />
              <Route path="/myaccount/:userID" element={ < UserPage recipe={recipe} uids={uids} /> } />
            </Routes>
          </AuthProvider>
        </div>
      </Router>
    </>
  )
}

export default App