import React, { useState, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext'
 
//Main Header
function Header({ currentUser }) {
    const [open, setOpen] = useState(false)
    const [error, setError] = useState("")
    const [tabOpen, setTabOpen] = useState(false)
    const { logout }  = useAuth()

    const [isMobile, setMobile] = useState(false)
    const [isDesktop, setDesktop] = useState(false)
    const nav = useNavigate()

    const updateMedia = () => {
        setMobile(window.innerWidth < 640)
        setDesktop(window.innerWidth >= 640)
    };

    useLayoutEffect(() => {
        updateMedia()
    }, [])

    async function handleSignOut() {
        setError('')
        
        try {
            await logout()
            nav("/login")
        } catch (err) {
            console.log('Logout Error: ', error)
            setError("Failed to logout")
        }
    }

    return (
        <>
                
            {/* This example requires Tailwind CSS v2.0+ */}
            <div className="relative bg-white z-[1]">
                <div className="max-w mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10"> {/*padding change */}
                        <div className="flex justify-start mx-[1rem] lg:w-0 lg:flex-1">
                            <div className="flex mr-[3rem] text-xl">
                                {/* when it is in mobile, set the path to "/" but if it is in desktop size change to the following: */}
                                {isDesktop &&<Link className="p-2 font-bold" to={"/"} key={"food"}>
                                    MukJa
                                </Link>}
                                {isMobile &&<Link className="p-2 font-bold" to={"/"} key={"food"}>
                                    MukJa
                                </Link>}
                                <div className="hidden md:flex">
                                    <ul className="flex flex-row list-none">
                                        <li className="ml-2 p-2">
                                            <Link to="/trendy-k-food">
                                                Trendy K-Food
                                            </Link>  
                                        </li>
                                        <li className='p-2'>
                                            <Link to="/old-fashion">
                                                Old Fashion
                                            </Link>
                                        </li>  
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Hamburger button */}
                        <div className="-mr-2 -my-2 md:hidden">
                            <button
                                type="button"
                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                onClick={() => setOpen(!open)}
                            >
                                <span className="sr-only">Open menu</span>
                                {/* Heroicon name: outline/menu */}
                                <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                                </svg>
                            </button>
                        </div>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <div onMouseOver={() => setTabOpen(true)} onMouseLeave={() => setTabOpen(false)}>
                                <ul className="flex flex-row list-none">
                                    <li className='bg-white text-lg hover:bg-gray-100 text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow type=myaccount'>
                                    {tabOpen && (
                                        <div 
                                            className="absolute top-10 right-[1.5rem]  mt-[0.88rem] w-50 border bg-white text-black py-2 rounded-md shadow-lg" 
                                        >   
                                            <div onMouseLeave={() => setTabOpen(false)}>                                    
                                                <div className="hover:bg-gray-200 cursor-pointer px-3 py-1">
                                                {
                                                    currentUser ? 
                                                    (<Link to={`/myaccount/${currentUser.uid}`}>My Account</Link>) 
                                                    :
                                                    ""
                                                }
                                                </div>

                                                <div className="hover:bg-gray-200 cursor-pointer px-3 py-1">                         
                                                    {
                                                        currentUser ? 
                                                        <Link to="/sendfeedback">Send Feedback</Link> 
                                                        :
                                                        ""
                                                    }
                                                </div>

                                                <div className="hover:bg-gray-200 cursor-pointer px-3 py-1">                         
                                                    {
                                                        currentUser ? 
                                                        (<div onClick={handleSignOut}>Log out</div>) 
                                                        :
                                                        (<Link to="/login">Sign up</Link>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )} 
                                    {
                                        currentUser ? <div>Profile</div> : <Link to="/login">Login</Link> 
                                    }
                                    </li>
                                </ul>
                            </div>          
                        </div>
                    </div>
                </div>

                {/* Mobile menu, show/hide based on mobile menu state. */}
                <div
                    className={
                        open
                        ?
                        "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        : 
                        "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right hidden"
                    }
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <Link className="ml-[2rem] text-lg font-bold" to="/">
                                    MukJa
                                    {/* <img src={logo} alt="logo" className='h-12'/>  */}
                                </Link>
                                <div className="-mr-2">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 
                                        hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        onClick={() => setOpen(!open)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        {/* Heroicon name: outline/x */}
                                        <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    <li
                                        className="p-3 flex items-center rounded-md hover:bg-gray-50"
                                    >
                                        <Link to="/trendy-k-food" className="flex flex-row">
                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                Trendy K-Food
                                            </span>
                                        </Link>
                                    </li>
                                    <li
                                        className="p-3 flex items-center rounded-md hover:bg-gray-50"
                                    >
                                        <Link to="/old-fashion-food" className="flex flex-row">
                                            <span className="ml-3 text-base font-medium text-gray-900">
                                                Old Fashion
                                            </span>
                                        </Link>
                                    </li>
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5 space-y-6">
                            <div>
                                <li className="list-none login-bamburger">
                                    {
                                        currentUser ? 
                                        (
                                            <Link 
                                                to={`/myaccount/${currentUser.uid}`}
                                                className="w-full flex items-center justify-center px-4 py-2 border border border-gray-400 rounded-md shadow-sm text-base font-medium text-white hover:text-black bg-black hover:bg-white active:bg-grey"                                        
                                            >
                                                My Account
                                            </Link>
                                        ) : (
                                            <div>
                                                <Link 
                                                    to="/login" 
                                                    className="w-full flex items-center justify-center px-4 py-2 border border-gray-400 rounded-md shadow font-semibold text-gray-800 bg-whte hover:bg-gray-100 text-lg"                                        
                                                >
                                                    Log In
                                                </Link>
                                            </div>
                                        )
                                    }
                                </li>
                                {/* Mobile signup section */}
                                <p className="flex flex-row justify-center mt-6 text-center text-base font-medium text-gray-500">
                                    {
                                        currentUser ? "MukJa" : "New user? "
                                    }
                                    <li className="ml-[1rem] list-none text-indigo-600 hover:text-indigo-500">
                                        {
                                            currentUser ? 
                                            "Log Out" :
                                            (<Link to="/login">Sign up</Link>)
                                        }
                                    </li>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header;
